<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :items="newsList"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :headers="headers"
          :search="search"
          title="Berita"
          subtitle="Halaman untuk menambahkan Berita"
          add-btn="Tambah Data"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @show-image="showImage"
          @delete-item="confirmDestroy"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @check-description="checkDescription"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || news.thumbnail === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Berita': 'Edit Berita'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col>
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="news.publish_date"
                    label="Tanggal Terbit"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    :rules="[validation.required]"
                    clearable
                    v-on="on"
                    @click="autofillDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="news.publish_date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="news.publish_at"
                    label="Jam Terbit"
                    :prepend-icon="icons.mdiClockTimeFourOutline"
                    readonly
                    outlined
                    v-bind="attrs"
                    dense
                    :rules="[validation.required]"
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="news.publish_at"
                  color="primary"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-text-field
            v-model="news.title"
            label="Judul"
            outlined
            dense
            :rules="[validation.required]"
          ></v-text-field>
        </div>
        <div class="mb-4">
          <CKEditor
            :value="initialContent"
            label="Content"
            outlined
            dense
            @input="handleCKeditor"
          ></CKEditor>
        </div>
        <FileInput
          :value="initialFile"
          label="Thumbnail"
          outlined
          :prependicon="icons.mdiImageMultipleOutline"
          dense
          @input="getFile"
        ></FileInput>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi News"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail.content"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Berita"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>

    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="news.thumbnail"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import CKEditor from '../../components/CkEditor.vue'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import FileInput from '../../components/FileInput.vue'
import ImageOverlay from '../../components/ImageOverlay.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'Berita',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    ImageOverlay,
    FileInput,
    SkeletonLoaderTable,
    MainCard,
    CKEditor,
  },

  data() {
    return {
      widthModal: 810,
      service: 'newshod',
      search: '',
      page: 1,
      totalItems: 0,
      totalPages: 0,
      dialog: null,
      width: 700,
      modalDialog: false,
      confirmDialog: false,
      imageOverlay: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      overlay: false,
      zIndex: 10,
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },
      validation: {
        required,
        integerValidator,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tanggal Terbit', value: 'publish_at' },
        { text: 'Judul', value: 'title' },
        { text: 'Isi Berita', value: 'news_content' },
        { text: 'Thumbnail', value: 'thumbnail' },
        { text: 'Penulis', value: 'kadis.name' },
        { text: 'Aksi', value: 'actions' },
      ],
      newsList: [],
      news: {
        uuid: '',
        kadis_uuid: '',
        title: '',
        content: '',
        thumbnail: null,
        publish_at: '',
        is_publish: 1,
        publish_date: '',
      },
      school: [],
      kadis_uuid: '',
      date_menu: false,
      time: null,
      menu2: false,
      detail: { content: '' },
      modalDescription: false,
      tempContent: '',
      tempImage: [],
      filterQuery: {
        news: '',
      },
      formValid: false,
    }
  },

  computed: {
    initialFile: {
      get() {
        if (typeof this.news.thumbnail === 'string') {
          return new File([this.news.thumbnail], this.news.thumbnail, { type: 'text/plain' })
        }

        return this.news.thumbnail
      },
      set() {
        return this.news.thumbnail
      },
    },
    initialContent: {
      get() {
        if (this.news.content === null) {
          return ''
        }

        return this.news.content
      },
      set() {
        return this.news.content
      },
    },
  },

  watch: {
    news: {
      handler() {
        const valid = []
        const requiredField = ['publish_date', 'publish_at', 'title', 'content']
        Object.entries(this.news).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listNews(this.filterQuery)
      },
    },
  },

  async mounted() {
    this.isLoadingTable = true
    this.getSchoolUuid()
    await this.listNews()
    this.isLoadingTable = false
  },

  methods: {
    cancelAction() {
      this.modalDialog = false
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    async checkDescription(uuid) {
      await this.$services.ApiServices.get('news', uuid).then(({ data }) => {
        this.detail = data.data
      })
      this.modalDescription = true
    },
    handleCKeditor(event) {
      this.news.content = event
      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        this.kadis_uuid = el.uuid
      })

      return true
    },

    autofillDate() {
      this.news.publish_date = new Date().toISOString().substr(0, 10)
    },

    async listNews(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        kadis_uuid: this.kadis_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.newsList = data.data.map((newsList, index) => ({
            ...newsList,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    resetForm() {
      this.news.uuid = ''
      this.news.title = ''
      this.news.content = ''
      this.news.publish_at = ''
      this.news.thumbnail = null
      this.publish_date = ''
      this.publish_time = ''
    },

    showFormAdd() {
      this.tempImage = []
      this.tempContent = ''
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true
      this.news.publish_at = `${this.news.publish_date} ${this.news.publish_at}`
      if (JSON.parse(localStorage.getItem('user')).user.role === 'admin') {
        this.kadis_uuid = ''
      }
      this.news.kadis_uuid = this.kadis_uuid
      this.formData = new FormData()
      Object.entries(this.news).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      const tempImage = this.tempContent.split('"')
      tempImage.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          this.tempImage.push(img)
        }
      })
      const image = this.news.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.listNews(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.resetForm()
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    async showFormEdit(uuid) {
      this.tempContent = ''
      this.tempImage = []
      this.dialog = 'edit'
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.news.is_publish = 1
          this.news.uuid = data.data.uuid
          this.news.kadis_uuid = data.data.school?.uuid ?? ''
          this.news.title = data.data.title
          this.news.content = data.data.content
          const image = data.data.content.split('"')
          image.forEach(async item => {
            const img = item.split('/').pop()
            if (img.length === 44) {
              this.tempImage.push(img)
            }
          })
          this.news.publish_at = data.data.publish_at
          this.news.thumbnail = data.data.thumbnail.split('/').pop()
          // eslint-disable-next-line prefer-destructuring
          this.news.publish_date = data.data.publish_at.split(' ')[0]
          // eslint-disable-next-line prefer-destructuring
          this.news.publish_at = data.data.publish_at.split(' ')[1].slice(0, 5)
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },

    async update() {
      this.isLoadingButton = true

      this.news.publish_at = `${this.news.publish_date} ${this.news.publish_at}`

      this.formData = new FormData()
      Object.entries(this.news).forEach(([key, item]) => {
        this.formData.append(key, item)
      })

      const image = this.news.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })

      const oldThumbnail = this.news.thumbnail
      if (typeof oldThumbnail && typeof this.formData.get('thumbnail') === 'string') {
        this.formData.delete('thumbnail')
      }
      if (this.formData.get('kadis_uuid') === '' || this.formData.get('kadis_uuid') === null) {
        this.formData.delete('kadis_uuid')
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.news.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listNews(this.filterQuery)
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.news.uuid = uuid
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.news.uuid).then(
        ({ data }) => {
          this.listNews(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(news) {
      this.filterQuery.news = news
      this.page = 1
      this.listNews(this.filterQuery)
    },

    async showImage(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.news = data.data
          this.news.thumbnail = data.data.thumbnail
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },

    getFile(data) {
      this.news.thumbnail = data
    },
  },
}
</script>
